var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"address-selector-container fill-height default--text px-3"},[_c('v-layout',{staticClass:"fill-height column mt-5 text-center"},[_c('ebsn-meta',{staticClass:"mx-auto text-center",attrs:{"target":_vm.category,"path":"category_info.TITLE","tag":"h2"}}),_c('ebsn-meta',{staticClass:"text-center mt-2",attrs:{"target":_vm.category,"path":"category_info.DESCRIPTION"}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1","container":false}}),_c('v-row',{staticClass:"justify-center align-center my-3 tabs"},_vm._l((_vm.deliveryServices),function(service,idx){return _c('v-col',{key:service?.deliveryServiceId,staticClass:"px-2",attrs:{"cols":_vm.deliveryServiceButtonsLength},on:{"click":function($event){return _vm.fetchAddresses(service, idx)}}},[_c('div',{staticClass:"tab rounded-md d-flex flex-column align-center justify-center flex-grow-1 py-3 py-sm-1 rounded-sm",class:service?.deliveryServiceId === _vm.selectedDeliveryServiceId
              ? `${service.cssClass} selected grey lighten-3`
              : `${service.cssClass}`},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-img',{staticClass:"mr-1",attrs:{"src":service.iconSource,"alt":'Immagine' + service.name,"contain":"","height":_vm.$vuetify.breakpoint.xs ? 60 : 44,"width":_vm.$vuetify.breakpoint.xs ? 70 : 44}}),_c('h4',{staticClass:"secondary--text"},[_vm._v(_vm._s(service.name))]),_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(service.descr))])],1)])])}),1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-list',{staticClass:"address-list pt-0",attrs:{"dense":""}},[(
            _vm.deliveryService?.deliveryServiceId == 2 &&
              _vm.eldersAddressEnabled &&
              !_vm.eldersInfoRead &&
              _vm.eldersAddress == null &&
              !_vm.loading
          )?_c('v-list-item',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.explenation"))+" "),_c('v-list-item-action',{staticClass:"d-block"},[_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.eldersInfoRead = true}}},[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.explenationAccept"))+" ")])],1)],1)],1):(!_vm.loading)?_c('v-list-item-group',{attrs:{"color":"secondary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},[(_vm.deliveryService?.deliveryServiceId == 1)?_c('div',{staticClass:"address-filter-container d-flex flex-column"},[_c('h5',{staticClass:"text-left text-uppercase"},[_vm._v("cerca un punto vendita")]),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[(_vm.googleEnabled)?_c('vue-google-autocomplete',{ref:"addresstosearchref",attrs:{"id":"pacman-input","classname":"w-100 rounded-pill grey lighten3 pa-5 google-autocomplete-input address-to-search","placeholder":_vm.$t(
                      'navbar.service.' +
                        _vm.selectedDeliveryServiceId +
                        '.searchLabel'
                    ),"icon":"$search","types":'geocode',"enable-geolocation":true,"country":"it"},on:{"placechanged":_vm.addressToSearchChanged}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-btn',{staticClass:"show-new-warehouse fill-height rounded-pill",staticStyle:{"height":"100%","min-height":"60px"},attrs:{"x-large":"","outlined":"","block":"","color":"secondary","text":""},on:{"click":function($event){return _vm.geolocate()}}},[_c('v-icon',[_vm._v("$locationPointer")]),_c('span',[_vm._v("cerca nelle vicinanza")])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"warehouse-list-container"},_vm._l((_vm.filteredAddresses),function(address){return _c('v-list-item',{key:address.addressId,staticClass:"pa-4 warehouse-row",on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-icon',{staticClass:"mr-2 ml-2 ml-sm-2",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(address.selected ? "$radioOn" : "$radioOff")+" ")]),_c('v-list-item-content',{staticClass:"ml-3"},[_c('v-list-item-title',{staticClass:"text-left secondary--text font-weight-bold text-caption text-sm-body-2 mb-1"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('span',{staticClass:"text-left default--text font-weight-regular text-sm-body-2"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + address.addressTypeId + ".list", address ))+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row align-center ml-0"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(address.addressType == 'work')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$work")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Indirizzo di consegna al lavoro")])]),(
                    _vm.deliveryService?.deliveryServiceId == 2 &&
                      _vm.eldersAddressEnabled &&
                      address.addressType != 'work'
                  )?[(_vm.eldersAddress == null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setAsEldersAddress(address)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t("addressSelector.elder.selectAddressBtn")))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.selectAddressTooltip"))+" ")])]):(_vm.eldersAddress.addressId == address.addressId)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v("$elder")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.elderIconTooltip"))+" ")])]):_vm._e()]:_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                        address.addressId ==
                          _vm.cart.user.defaultStoreAddressId ||
                          address.addressId ==
                            _vm.cart.user.defaultDeliveryAddressId
                      )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$favourite")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? _vm.$t("addressSelector.usualAddress") : _vm.$t("addressSelector.usualPdv")))])]),(address.addressClass.length > 0)?_vm._l((_vm.filteredAddressClasses(
                      address.addressClass
                    )),function(addressClass){return _c('AddressClass',{key:addressClass['address-class-id'],attrs:{"addressClass":addressClass}})}):_vm._e(),(
                    _vm.deliveryService.deliveryServiceId == 1 &&
                      _vm.userData?.defaultStoreAddress?.addressId !==
                        address.addressId
                  )?_c('v-btn',{staticClass:"ml-3 set-fav-wh",attrs:{"icon":"","small":_vm.$vuetify.breakpoint.xs},on:{"click":function($event){$event.stopPropagation();return _vm.setFavWh(address)}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("$star")])],1):_vm._e(),(
                    _vm.userData?.defaultStoreAddress?.addressId ===
                      address.addressId
                  )?_c('v-icon',{attrs:{"color":"#FFB502"}},[_vm._v("$starFull")]):_vm._e()],2)],1)}),1)]):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end"},[(
            _vm.deliveryService?.deliveryServiceId === 2 &&
              _vm.filteredAddresses.length == 0
          )?_c('v-btn',{attrs:{"large":"","depressed":"","color":"secondary","to":{ name: 'Page', params: { pageName: 'i-servizi-tigros' } },"align":"center"},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" i nostri servizi ")]):_vm._e(),(_vm.deliveryService?.deliveryServiceId === 2)?_c('v-btn',{attrs:{"color":"secondary","large":"","depressed":"","to":{ name: 'EditAddresses' }},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" AGGIUNGI INDIRIZZO ")]):_vm._e()],1)],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2","container":false}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }