var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leaflet-slider",staticStyle:{"position":"relative"}},[_c('swiper',{staticClass:"py-3",attrs:{"ef":"swiperRef","options":_vm.$ebsn.meta(
        _vm.config,
        'metaData.categoryblocktype_LeafletSliderWrapper.JSON_CONFIG',
        _vm.swiperOptions
      )}},_vm._l((_vm.leaflets),function(leaflet){return _c('swiper-slide',{key:leaflet.leafletId,staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"leaflet-descr mb-2"},[_c('h4',{staticClass:"leaflet-title my-0"},[_vm._v(" "+_vm._s(leaflet.title)+" ")]),_c('span',{staticClass:"leaflet-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("leaflet.validity.from"))+" "+_vm._s(_vm._f("dayjs")(leaflet.fromDate,"format", "D MMMM"))+" "+_vm._s(_vm.$t("leaflet.validity.to"))+" "+_vm._s(_vm._f("dayjs")(leaflet.toDate,"format", "D MMMM")))])]),_c('v-card',{staticClass:"fill-height leaflet-card rounded-md elevation-3 pa-3",attrs:{"aria-label":`${_vm.$t('leaflet.title')} ${leaflet.title}`,"to":{
          name: 'Leaflet',
          params: {
            leafletId: leaflet.slug ? leaflet.slug : leaflet?.leafletId
          }
        }}},[_c('v-img',{staticClass:"leaflet-card__image",attrs:{"contain":"","width":"230px","height":"220px","src":`${leaflet.baseLocation}${leaflet.firstPagePreview}`}})],1)],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":_vm.showBullets,"showArrows":_vm.showArrows,"paginationClass":_vm.paginationClass,"length":_vm.leaflets.length}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }