<template functional>
  <div class="product-qty">
    <div
      class="rounded-pill qty-wrap"
      :class="{
        'not-empty': parent.quantity > 0
      }"
      @mousedown.stop
    >
      <v-btn
        color="primary"
        width="45"
        height="45"
        aria-label="Diminuisci quantità"
        depressed
        class="minus rounded-pill"
        @click.stop.prevent="
          parent.minus(
            props.updateCart,
            { infos: { ...props.selectedOptions } },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <v-icon>$minus</v-icon>
      </v-btn>
      <div class="val-cnt" @click.stop.prevent="" @mousedown.stop>
        <span class="val">{{ parent.quantity }}{{ parent.unit }}</span>
        <span class="small">{{ parent.quantityPerUnit }}</span>
      </div>
      <v-btn
        width="45"
        height="45"
        aria-label="Aumenta quantità"
        class="plus rounded-pill"
        depressed
        @click.stop.prevent="
          parent.plus(
            props.updateCart,
            {
              infos: {
                ...props.selectedOptions
              }
            },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <v-icon v-if="parent.quantity > 0">$plus</v-icon>
        <template v-else>
          <v-icon>$cart</v-icon>
        </template>
      </v-btn>
    </div>
  </div>
</template>
<style>
.plus.v-btn:not(.v-btn--round).v-size--large {
  height: 50px !important;
}
</style>
<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined }
  }
};
</script>
